import Banner from "../../components/Banner"
import { Text, Box } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import SolutionsGrid from "../../components/SolutionsGrid"
import SolutionTextBox from "../../components/SolutionTextBox"

export const query = graphql`
  query {
    mobileBanner: file(
      relativePath: { eq: "solutionsOfficeBannerMobile.png" }
    ) {
      ...fluidImage
    }
    desktopBanner: file(relativePath: { eq: "solutionsOfficeBanner.png" }) {
      ...fluidImage
    }
    imageOne: file(relativePath: { eq: "solutionsOffice1.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "solutionsOffice2.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "solutionsOffice3.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "solutionsOffice4.png" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "solutionsOffice5.png" }) {
      ...fluidImage
    }
    imageSix: file(relativePath: { eq: "solutionsOffice6.png" }) {
      ...fluidImage
    }
    imageSeven: file(relativePath: { eq: "solutionsOffice7.png" }) {
      ...fluidImage
    }
    imageEight: file(relativePath: { eq: "solutionsOffice8.png" }) {
      ...fluidImage
    }
    imageNine: file(relativePath: { eq: "solutionsOffice9.png" }) {
      ...fluidImage
    }
  }
`
export default function Office({ data }) {
  const images = [
    data.imageOne.childImageSharp.fluid,
    data.imageTwo.childImageSharp.fluid,
    data.imageThree.childImageSharp.fluid,
    data.imageFour.childImageSharp.fluid,
    data.imageFive.childImageSharp.fluid,
    data.imageSix.childImageSharp.fluid,
    data.imageSeven.childImageSharp.fluid,
    data.imageEight.childImageSharp.fluid,
    data.imageNine.childImageSharp.fluid,
  ]

  return (
    <Layout>
      <SEO title="Office Solutions" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Find a solution that suits your OFFICE!"
        body={
          <Text variant="default" color="accent">
            Our designs and products are fashioned not only to introduce
            greenery to an office but also to elevate the look and feel of the
            space.
          </Text>
        }
        alt="Office Solutions"
      />
      <SolutionsGrid>
        <Box sx={{ gridColumn: "1 / -1" }}>
          <SolutionTextBox>
            The importance of greenery in our day to day life is now being
            recognised by all industries, restaurants, organisations, start-ups
            and government bodies. There is a concentrated effort to breathe
            more life and light in an office space across industries and we have
            been fortunate to be in the forefront of this positive trend. Right
            from the boardrooms to cubicles, from pantries to canteens and from
            cabins to meeting rooms, we offer a wide variety of planters, low
            maintenance plants, maintenance services and customised plant
            staging to make sure that an office environment is bright, fresh and
            inviting. We also offer plants on rental, where our plant experts
            keep the plants pruned and trimmed and when the plants run into
            problems and cannot be saved, we change out the plants with new
            plants.
          </SolutionTextBox>
        </Box>
        {images.map((image, index) => {
          return <Img key={index} fluid={image} alt="office solution" />
        })}
      </SolutionsGrid>
    </Layout>
  )
}
